import React, {useEffect, useState} from 'react';
import './App.css';
import {Card, List} from "antd";
import {ReactComponent as IconLife} from './assets/icons/ic_lie.svg'
import {ReactComponent as IconSleep} from './assets/icons/ic_sleep.svg'
import {ReactComponent as IconDuanwu} from './assets/icons/ic_duanwu.svg'
import {ReactComponent as Icon11} from './assets/icons/ic_11.svg'
import {ReactComponent as IconChunjie} from './assets/icons/ic_chunjie.svg'
import {ReactComponent as IconErtong} from './assets/icons/ic_ertong.svg'
import {ReactComponent as IconFuqin} from './assets/icons/ic_fuqin.svg'
import {ReactComponent as IconJieri} from './assets/icons/ic_jieri.svg'
import {ReactComponent as IconQix} from './assets/icons/ic_qixi.svg'
import {ReactComponent as IconShengdan} from './assets/icons/ic_shengdan.svg'
import {ReactComponent as IconZhongqiu} from './assets/icons/ic_zhongqiu.svg'
import {ReactComponent as IconGuoqing} from './assets/icons/ic_guoqin.svg'
import {ReactComponent as IconYuandan} from './assets/icons/ic_yuandan.svg'
import axios, {AxiosResponse} from 'axios';


export interface Article {
    title: string;
    link: string;
    hotness: string;
    image: string;
}

export interface Site {
    key: string;
    name: string;
    link: string;
    sort: number;
}

export interface Item {
    site: Site;
    articles: Article[];
    time: number;
}

export interface Holiday {
    name: string;
    days: number;
}

export interface Data {
    items: Item[];
    holidays: Holiday[];
    weekend: number;
    count: number;
}

export interface RootObject {
    code: number;
    msg: string;
    data: Data;
}

function App() {

    // const items = Array.from({length: 30}, (_, index) => index + 1);
    const [week, setWeek] = useState<string>('-');
    const [count, setCount] = useState('-');
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [items, setItems] = useState<Item[]>();

    // 强制请求一次

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response: AxiosResponse<RootObject> = await axios.post('https://laoliu.wang/moyu/api/api/data');
            // const response: AxiosResponse<RootObject> = await axios.post('http://127.0.0.1:8080/api/data');
            let rootObject = response?.data;
            if (!rootObject) return;
            setWeek(rootObject?.data?.weekend?.toString())
            setHolidays(rootObject?.data?.holidays)
            setItems(rootObject.data.items);
            setCount(rootObject?.data?.count?.toString())
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function getIcon(name: string) {
        if (name.includes("端午")) {
            return <IconDuanwu width={32} height={32}/>
        } else if (name.includes("双11")) {
            return <Icon11 width={32} height={32}/>
        } else if (name.includes("春节")) {
            return <IconChunjie width={32} height={32}/>
        } else if (name.includes("儿童")) {
            return <IconErtong width={32} height={32}/>
        } else if (name.includes("父亲")) {
            return <IconFuqin width={32} height={32}/>
        } else if (name.includes("七夕")) {
            return <IconQix width={32} height={32}/>
        } else if (name.includes("圣诞")) {
            return <IconShengdan width={32} height={32}/>
        } else if (name.includes("国庆")) {
            return <IconGuoqing width={32} height={32}/>
        } else if (name.includes("元旦")) {
            return <IconYuandan width={32} height={32}/>
        } else if (name.includes("中秋")) {
            return <IconZhongqiu width={32} height={32} style={{fill: '#1296db'}}/>
        } else {
            return <IconJieri width={32} height={32} style={{fill: '#1296db'}}/>
        }
    }

    return (
        // 全屏显示
        <div style={{backgroundColor: '#131317', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '16px',
                    backgroundColor: 'black',
                    marginBottom: '16px',
                }}
            >
                <IconLife width={32} height={32} style={{fill: '#1296db'}}/>
                <div style={{display: 'flex', alignItems: 'center', fontSize: 16, color: 'white', marginLeft: 16}}>
                    躺平摸鱼了...
                </div>
                <div style={{flex: 1}}/>
                <div style={{color: "white", fontSize: 14, marginRight: 16}}>
                    今日躺平摸鱼人数:{count}
                </div>
            </div>
            <span style={{display: 'flex'}}>

            <List style={{padding: '16px', backgroundColor: '#131317', flex: 1}}
                  grid={{gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 6}}
                  dataSource={items}
                  renderItem={(item) => (
                      <List.Item>
                          <Card
                              bordered={false}
                              className={'custom-card'}
                              style={{backgroundColor: '#17181c', borderRadius: '15px', color: '#fff'}}
                          >
                              <a href={item.site.link} target="_blank" style={{color: '#ccc'}}>
                                  <h3>{item.site.name}</h3>
                              </a>
                              <div
                                  className="div"
                              >
                                  <ul style={{margin: 0, padding: 0}}>
                                      {/*{item.articles && item.articles.length > 0 && item.articles[0].link}*/}

                                       {
                                          item.articles && item.articles.length > 0 &&
                                          item.articles.map((data: Article, index: number) => (
                                              <a href={data.link} target="_blank" rel="noopener noreferrer">
                                                  <div style={{
                                                      display: 'flex',
                                                      marginTop: 10,
                                                      color: '#ccc',
                                                  }}>
                                                      <span
                                                          style={{color: `${index === 0 ? '#a7242a' : (index === 1 ? '#b64c24' : (index === 2 ? '#b27e3b' : '#ccc'))}`}}>{index + 1}.</span>
                                                      <span style={{margin: '0 10px', flex: 1}}>{data.title}</span>
                                                      <span style={{display:'none'}}>446万</span>
                                                  </div>
                                              </a>
                                          ))
                                      }
                                  </ul>
                              </div>
                          </Card>
                      </List.Item>
                  )}
            />

                <div style={{width: '25%', maxWidth: '350px'}}>
                    <Card
                        bordered={false}
                        style={{
                            backgroundColor: '#17181c',
                            borderRadius: '15px',
                            color: '#fff',
                            padding: 0,
                            overflow: 'hidden', // 添加滚动效果
                            scrollbarWidth: 'none', // 设置滚动条宽度
                            scrollbarColor: 'rgba(255, 255, 255, 0) rgba(0, 0, 0, 0)', // 设置滚动条颜色
                        }}
                    >

                            <div style={{display: 'flex', alignItems: "center"}}>
                                <IconSleep width={32} height={32} style={{fill: '#1296db'}}/>
                                <span style={{fontSize: 16, color: "white", flex: 1, marginLeft: 16}}>距离周末:</span>
                                <span style={{fontSize: 24, color: "white"}}>{week}</span>
                                <span style={{fontSize: 16, color: "white", marginLeft: 10}}>天</span>
                            </div>


                        {
                            holidays.map((item, index) => (
                                <div style={{display: 'flex', alignItems: "center", marginTop: 16}}>
                                    {getIcon(item.name)}
                                    <span style={{
                                        fontSize: 16,
                                        color: "white",
                                        flex: 1,
                                        marginLeft: 16
                                    }}>距离{item.name}:</span>
                                    <span style={{fontSize: 24, color: "white"}}>{item.days}</span>
                                    <span style={{fontSize: 16, color: "white", marginLeft: 10}}>天</span>
                                </div>
                            ))
                        }

                    </Card>
                </div>
                </span>

            <div style={{flex: 1}}></div>

            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                    color: "white",
                    backgroundColor: 'black',
                    marginBottom: '16px',
                }}
            >
                <div style={{flex: 1}}/>
                <a style={{color: "white"}} href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18053277号</a>
                <span style={{margin: '0 10px'}}>|</span>
                <a style={{color: "white"}} href="https://beian.miit.gov.cn/"
                   target="_blank">浙ICP备18053277号-2</a>
                <div style={{flex: 1}}/>
            </div>
        </div>
    );
}

export default App;